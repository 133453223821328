import { clientFileDownload } from "common-components-nuxt/utils/download";

export function useApplicationDownload(afterDownload?: () => void) {
  const localization = useGlobalLocalization();

  const downloadApplicationRequest = ref<{
    applicationId: number;
  }>();

  const { data: result, pending: downloadPending } = useApi(
    "/api/user/downloadApplication",
    {
      body: downloadApplicationRequest,
      method: "post",
      immediate: false,
    },
  );
  downloadPending.value = false;

  watch(result, function (newValue) {
    if (
      newValue !== null &&
      (isBlob(newValue) || typeof newValue === "string")
    ) {
      clientFileDownload(newValue, `${localization.applicationFileName}.pdf`);
      if (afterDownload !== undefined) {
        afterDownload();
      }
    }
  });

  const downloadSignedApplicationRequest = ref<{
    applicationId: number;
  }>();

  const { data: signedResult, pending: signedDownloadPending } = useApi(
    "/api/user/downloadSigned",
    {
      body: downloadSignedApplicationRequest,
      method: "post",
      immediate: false,
    },
  );
  signedDownloadPending.value = false;

  watch(signedResult, function (newValue) {
    if (
      newValue !== null &&
      (isBlob(newValue) || typeof newValue === "string")
    ) {
      clientFileDownload(
        newValue,
        `${localization.signedApplicationFileName}.pdf`,
      );
      if (afterDownload !== undefined) {
        afterDownload();
      }
    }
  });

  function downloadApplication(
    applicationId: number | undefined,
    signed: boolean | undefined,
  ) {
    if (applicationId !== undefined && signed === true) {
      downloadSignedApplicationRequest.value = {
        applicationId: applicationId,
      };
    } else if (applicationId !== undefined) {
      downloadApplicationRequest.value = {
        applicationId: applicationId,
      };
    }
  }

  const pending = computed(() => {
    return downloadPending.value || signedDownloadPending.value;
  });

  return { downloadApplication, pending };
}
