import { clientFileDownload } from "common-components-nuxt/utils/download";

export function useQualificationCardDownload(afterDownload?: () => void) {
  const localization = useGlobalLocalization();

  const downloadQualificationCardRequest = ref<{
    level: number | undefined;
    qualificationGroupId: number;
  }>();

  const { data: result, pending: downloadPending } = useApi(
    "/api/user/downloadQualification",
    {
      body: downloadQualificationCardRequest,
      method: "post",
      immediate: false,
    },
  );
  downloadPending.value = false;

  watch(result, function (newValue) {
    if (
      newValue !== null &&
      (isBlob(newValue) || typeof newValue === "string")
    ) {
      clientFileDownload(newValue, `${localization.qualificationFileName}.pdf`);
      if (afterDownload !== undefined) {
        afterDownload();
      }
    }
  });

  const downloadSignedQualificationRequest = ref<{
    level: number | undefined;
    qualificationGroupId: number;
  }>();

  const { data: signedResult, pending: signedDownloadPending } = useApi(
    "/api/user/downloadQualificationSigned",
    {
      body: downloadSignedQualificationRequest,
      method: "post",
      immediate: false,
    },
  );
  signedDownloadPending.value = false;

  watch(signedResult, function (newValue) {
    if (
      newValue !== null &&
      (isBlob(newValue) || typeof newValue === "string")
    ) {
      clientFileDownload(
        newValue,
        `${localization.signedQualificationFileName}.pdf`,
      );
      if (afterDownload !== undefined) {
        afterDownload();
      }
    }
  });

  function downloadQualificationCard(
    qualificationGroupIdOrSchoolId: number | undefined,
    level: number | undefined,
    signed: boolean | undefined,
  ) {
    if (
      qualificationGroupIdOrSchoolId !== undefined &&
      level != undefined &&
      signed === true
    ) {
      downloadSignedQualificationRequest.value = {
        level: level,
        qualificationGroupId: qualificationGroupIdOrSchoolId,
      };
    } else if (
      qualificationGroupIdOrSchoolId !== undefined &&
      level != undefined
    ) {
      downloadQualificationCardRequest.value = {
        level: level,
        qualificationGroupId: qualificationGroupIdOrSchoolId,
      };
    }
  }

  const pending = computed(() => {
    return downloadPending.value || signedDownloadPending.value;
  });

  return { downloadQualificationCard, pending };
}
