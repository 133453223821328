import type {
  ParentConfirmationData,
  PlaceResignationData,
  UserApplicationStatusData,
} from "@/types/user/panelData";

export async function useUserPanel() {
  const userPanelStore = useUserPanelStore();
  const { setLevel, refresh, initialize, updateSignData } = userPanelStore;
  const { level, data, activePanel, activeUserPage } =
    storeToRefs(userPanelStore);
  const route = useRoute();

  const { downloadApplication, pending: downloadApplicationPending } =
    useApplicationDownload(() => {
      if (data.value?.options.lockApplicationEditionAfterPrintPublicEnabled) {
        refresh();
      }
    });

  const {
    downloadQualificationCard,
    pending: downloadQualificationCardPending,
  } = useQualificationCardDownload(() => {
    if (data.value?.options.lockApplicationEditionAfterPrintPublicEnabled) {
      refresh();
    }
  });

  await initialize(route.query.activePanel);

  const autoRun = computed(() => {
    return data.value?.autoRun === true;
  });

  const notoficationsRequest = computed(() => {
    if (level.value !== undefined) {
      return {
        id: level.value,
      };
    }
  });

  const { data: notificationsData, refresh: refreshNotifications } = useApi(
    "/api/user/notificationsData",
    {
      body: notoficationsRequest,
      method: "post",
      immediate: false,
      watch: false,
    },
  );

  watch(notificationsData, (newValue) => {
    if (newValue && data.value) {
      data.value.notificationsData = newValue;
    }
  });

  const notifications = computed(() => {
    return data.value?.notificationsData;
  });

  const parentConfirmationData = computed(() => {
    return data.value?.parentConfirmationData;
  });

  const hasParentConfirmations = computed(() => {
    return (
      parentConfirmationData.value &&
      parentConfirmationData.value.availableConfirmations !== undefined &&
      parentConfirmationData.value.availableConfirmations.length > 0
    );
  });

  const hasParentCancellations = computed(() => {
    return (
      parentConfirmationData.value &&
      parentConfirmationData.value.availableCancellations !== undefined &&
      parentConfirmationData.value.availableCancellations.length > 0
    );
  });

  const placeResignationData = computed(() => {
    return data.value?.placeResignationData;
  });

  const hasPlaceResignations = computed(() => {
    return (
      placeResignationData.value &&
      placeResignationData.value.availableResignations !== undefined &&
      placeResignationData.value.availableResignations.length > 0
    );
  });

  const hasPoints = computed(() => {
    return (
      data.value?.statusData.priorityData.some(
        (priorityData) => priorityData.points !== undefined,
      ) === true
    );
  });

  const hasMinPoints = computed(() => {
    return (
      data.value?.statusData.priorityData.some(
        (priorityData) => priorityData.minPoints !== undefined,
      ) === true
    );
  });

  const hasCandidateCount = computed(() => {
    return (
      data.value?.statusData.priorityData.some(
        (priorityData) => priorityData.candidateCount !== undefined,
      ) === true && autoRun.value
    );
  });

  const hasWaitListNumber = computed(() => {
    return (
      data.value?.statusData.priorityData.some(
        (priorityData) => priorityData.waitListNumber !== undefined,
      ) === true && autoRun.value
    );
  });

  const hasPriorities = computed(() => {
    return data.value?.statusData.priorityData.some(
      (priorityData) => priorityData.priority !== undefined,
    );
  });

  function setParentConfirmationData(
    parentConfirmationData: ParentConfirmationData,
  ) {
    if (data.value) {
      data.value.parentConfirmationData = parentConfirmationData;
    }
  }

  function setPlaceResignationData(placeResignationData: PlaceResignationData) {
    if (data.value) {
      data.value.placeResignationData = placeResignationData;
      if (placeResignationData.parentConfirmationData) {
        setParentConfirmationData(placeResignationData.parentConfirmationData);
      }
    }
  }

  function setUserApplicationStatusData(
    userApplicationStatusData: UserApplicationStatusData,
  ) {
    if (data.value) {
      data.value.statusData = userApplicationStatusData;
    }
  }

  function setActivePanel(panel: "page" | "navigation") {
    activePanel.value = panel;
    return navigateTo({
      query: { level: level.value, activePanel: activePanel.value },
    });
  }
  function displayPointGroup(groupPoints: string | undefined) {
    if (!groupPoints) {
      return "";
    }

    const jsonObject = JSON.parse(groupPoints) as Record<string, string>;
    return Object.entries(jsonObject)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
  }

  const invalidPrioritiesMap = computed(() => {
    if (
      !data.value?.prioritiesEditable ||
      data.value.fullAdmissionPointIds === undefined ||
      data.value.fullAdmissionPointIds.length == 0
    ) {
      return new Map<number, string>();
    }
    const resultMap = new Map<number, string>();
    if (
      data.value &&
      data.value?.statusData &&
      data.value?.statusData.priorityData
    ) {
      for (const priority of data.value.statusData.priorityData) {
        if (
          !priority.priorityConfirmation &&
          priority.admissionPointId &&
          data.value.fullAdmissionPointIds.includes(priority.admissionPointId)
        ) {
          resultMap.set(
            priority.admissionPointId,
            data.value.localization.noPlacesInAdmissionPoint,
          );
        }
      }
    }

    return resultMap;
  });

  return {
    data,
    notifications,
    parentConfirmationData,
    hasParentConfirmations,
    hasParentCancellations,
    placeResignationData,
    hasPlaceResignations,
    level,
    autoRun,
    refresh,
    refreshNotifications,
    downloadApplication,
    downloadApplicationPending,
    downloadQualificationCard,
    downloadQualificationCardPending,
    setParentConfirmationData,
    setPlaceResignationData,
    setUserApplicationStatusData,
    setLevel,
    setActivePanel,
    updateSignData,
    activePanel,
    activeUserPage,
    hasPoints,
    hasMinPoints,
    hasCandidateCount,
    hasWaitListNumber,
    hasPriorities,
    displayPointGroup,
    invalidPrioritiesMap,
  };
}
